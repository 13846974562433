<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">A. Verifying the Absolute Zero of Temperature</h2>
      <p class="mb-3">
        Using the information determined from the simulation, answer each of the questions below.
      </p>

      <p class="mb-3">
        a) What was the atmospheric pressure in the lab during the experiment in units of mm Hg, cm
        Hg, and cm H<sub>2</sub>O? <b>Note:</b> To convert P<sub>atm</sub> from cm Hg to cm
        H<sub>2</sub>O, multiply by 13.57 cm H<sub>2</sub>O / cm Hg.
      </p>

      <p class="mb-5 pl-8">
        <calculation-input
          v-model="inputs.PatmHgmm"
          class="mb-2 mt-2 pb-1"
          prepend-text="$\text{P}_\text{atm}\text{:}$"
          append-text="$\text{mm Hg}$"
          dense
          :disabled="!allowEditing"
        />
        <calculation-input
          v-model="inputs.PatmHgcm"
          class="mb-2 mt-2 pb-1"
          prepend-text="$\text{P}_\text{atm}\text{:}$"
          append-text="$\text{cm Hg}$"
          dense
          :disabled="!allowEditing"
        />
        <calculation-input
          v-model="inputs.PatmH2Ocm"
          class="mb-2 mt-2"
          prepend-text="$\text{P}_\text{atm}\text{:}$"
          append-text="$\text{cm H}_2\text{O}$"
          dense
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-3">
        b) Fill in the table below with the measured manometer heights, h<sub>i,l</sub> and h<sub
          >i,r</sub
        >
        and temperatures, t<sub>i</sub>, from the experiment as well as the calculated P<sub
          >total</sub
        >
        values from each trial. Recall that P<sub>total</sub> = P<sub>atm</sub> + h<sub>r</sub> -
        h<sub>l</sub>.
      </p>

      <p class="mb-3 pl-4">
        <v-simple-table>
          <thead>
            <tr>
              <th style="font-size: 14px">Height right (cm H<sub>2</sub>O)</th>
              <th style="font-size: 14px">Height left (cm H<sub>2</sub>O)</th>
              <th style="font-size: 14px">Temperature (<sup>o</sup>C)</th>
              <th style="font-size: 14px">P<sub>total</sub> (cm H<sub>2</sub>O)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in 5" :key="i">
              <td>
                <calculation-input
                  v-model="inputs[`h${i}right`]"
                  class="mb-2 mt-2"
                  :prepend-text="`$\\text{h}_\\text{${i},r}\\text{:}$`"
                  dense
                  :disabled="!allowEditing"
                />
              </td>
              <td>
                <calculation-input
                  v-model="inputs[`h${i}left`]"
                  class="mb-2 mt-2"
                  :prepend-text="`$\\text{h}_\\text{${i},l}\\text{:}$`"
                  dense
                  :disabled="!allowEditing"
                />
              </td>
              <td>
                <calculation-input
                  v-model="inputs[`temp${i}`]"
                  class="mb-2 mt-2"
                  :prepend-text="`$\\text{t}_${i}\\text{:}$`"
                  dense
                  :disabled="!allowEditing"
                />
              </td>
              <td>
                <calculation-input
                  v-model="inputs[`Ptotal${i}`]"
                  class="mb-2 mt-2"
                  :prepend-text="`$\\text{P}_${i}\\text{:}$`"
                  dense
                  :disabled="!allowEditing"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </p>

      <p class="mb-3">
        Using graph paper or a spreadsheet software package, make a graph of P<sub>total</sub> vs t
        (in degrees Celsius). The resulting graph should be a straight line with the equation P = mt
        + b, where m is the slope of the line of best fit and b is a constant corresponding to the
        y-intercept. Once you have the equation for the line of best fit, answer the questions
        below:
      </p>

      <p class="mb-3">c) What is the linear equation for P<sub>total</sub>?</p>

      <calculation-input
        v-model="inputs.PeqnSlope"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{Slope:}$"
        append-text="$\text{cm H}_2\text{O/}^\circ\text{C}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.PeqnIntercept"
        class="mb-2 mt-2 pb-2"
        prepend-text="$\text{Intercept:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />

      <stemble-latex class="mb-5" :content="equationLatex" />

      <p class="mb-3">
        d) Determine the temperature, t<sub>0</sub>, at which P<sub>total</sub> is equal to 0.
      </p>

      <calculation-input
        v-model="inputs.T0degC"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{t}_0\text{:}$"
        append-text="$^\circ\text{C}$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        e) Now let k = -t<sub>0</sub> and T = t + k where T is a temperature in Kelvin and t is a
        temperature in degrees Celsius. On the Kelvin scale, T = 0 is absolute zero which is equal
        to t<sub>0</sub> in <sup>o</sup>C. In your equation in part c), substitute T - k for t and
        write a new equation for P<sub>total</sub> (showing that it has the form of P<sub
          >total</sub
        >
        = mT.
      </p>

      <v-text-field
        v-model="inputs.PeqnK"
        :prefix="eqnLabel"
        hint="Write an equation of the form aT where a is an actual coefficient"
        outlined
        dense
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'GasesReportSheetA',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        PatmHgmm: null,
        PatmHgcm: null,
        PatmH2Ocm: null,
        h1right: null,
        h1left: null,
        temp1: null,
        Ptotal1: null,
        h2right: null,
        h2left: null,
        temp2: null,
        Ptotal2: null,
        h3right: null,
        h3left: null,
        temp3: null,
        Ptotal3: null,
        h4right: null,
        h4left: null,
        temp4: null,
        Ptotal4: null,
        h5right: null,
        h5left: null,
        temp5: null,
        Ptotal5: null,
        PeqnSlope: null,
        PeqnIntercept: null,
        T0degC: null,
        PeqnK: null,
      },
    };
  },
  computed: {
    eqnLabel() {
      return 'Ptotal';
    },
    equationLatex() {
      let result = '\\text{P}_\\text{total} = ';
      if (!this.inputs.PeqnSlope && !this.inputs.PeqnIntercept) {
        result += '\\text{fill in the above fields to see your equation}';
      } else {
        if (this.inputs.PeqnSlope) {
          result += `${this.inputs.PeqnSlope}\\text{t}`;
        }
        if (this.inputs.PeqnSlope && this.inputs.PeqnIntercept) {
          result += ' + ';
        }
        if (this.inputs.PeqnIntercept) {
          result += this.inputs.PeqnIntercept;
        }
      }

      return `$$${result}$$`;
    },
  },
};
</script>
